import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="container">
        <header className="header">
          <h1>哗啦啦流量星球</h1>
          <h2>碰一碰AI矩阵拓客</h2>
        </header>
        <section className="product-intro">
          <p>
            碰一碰AI矩阵拓客分发系统就是用户通过碰一碰，一键转发商家指定的视频广告，实现海量曝光和矩阵分发，快速形成裂变。用户可以一键发布推广视频，并在用户发布视频上附上地理位置、活动话题。推广视频会在用户的通讯录好友和位置附近进行曝光，看到视频的用户可以获取相关优惠信息，达到把顾客的“朋友”引流到店里消费的目的。
          </p>
        </section>
        <section className="steps">
          <h3>操作步骤：</h3>
          <ol>
            <li>拿起手机贴近“碰一碰智能硬件”。</li>
            <li>进入浏览器页面，打开“浏览器”页面。</li>
            <li>一键发抖音、发小红书、发微信、发大众点评打卡、连门店WIFI。</li>
          </ol>
        </section>
        <section className="values">
          <h3>核心价值：</h3>
          <ul>
            <li><strong>裂变强:</strong> 假设每条200个曝光，每天100人转发，即每天20000+曝光。</li>
            <li><strong>素材多:</strong> AI生成种草文案 AI生成脚本短视频。</li>
            <li><strong>发布快:</strong> 只需3步，即可发布，商家/用户都受益。</li>
            <li><strong>本地化:</strong> 视频挂载POI、话题创建，直击本地流量。</li>
          </ul>
        </section>
        <section className="advantages">
          <h3>核心优势：</h3>
          <ul>
            <li><strong>超低成本:</strong> 单条视频成本低至1.5元，单次曝光成本低至3分钱。</li>
            <li><strong>傻瓜操作:</strong> 只需一个碰碰卡轻松搞定。</li>
            <li><strong>精准曝光:</strong> 自带POI定位，锁定周边精准流量。</li>
            <li><strong>快速裂变:</strong> 借助客户视频平台快速裂变。</li>
          </ul>
        </section>
        <footer className="footer">
          <p>Powered by 哗啦啦流量星球 浙ICP备 2024098728号-2</p>
        </footer>
      </div>
  );
}

export default App;
